<template>
  <div>
    <!--begin::DocumentType-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Document Type View</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mb-3 mr-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span
                  class="v-btn__content"
                  @click="redirectToEditDT($route.params.id)"
                >
                  Edit
                </span>
              </button>
              <button
                type="button"
                class="mt-3 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span
                  class="v-btn__content"
                  @click="redirectToDocumentTypeList()"
                >
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form
          class="form"
          novalidate="novalidate"
          id="kt_document_type_form"
        >
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <b>Company Name:</b> {{ document_type.company.name }}
                </v-col>
                
              </v-row>

              <v-row no-gutters class="mt-3">
                <v-col cols="12" sm="6">
                  <b>Document Type: </b> {{ document_type.type }}
                </v-col>                
              </v-row>              
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::DocumentType-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COMPANY_USERS } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

export default {
  name: "documentTypeCreate",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      document_type: {
        name: "",
        company_id: ""
      },
      company_users: "",
      company_user_list: [],
      valid: true,
      disable_btn: false,
      edit_form: false,
      show1: true
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["companyUsers"]),
  },
  mounted() {
    let context = this;
    context.getCompanyUsers();
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "document-types/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.document_type) {
            context.document_type = result.data.document_type;
          } else {
            context.$router.go(-1); //go back to list
            context.disable_btn = false;
            Swal.fire(result.data.error, "", "error");
          }
        },
        function() {
          context.$router.go(-1); //go back to list
          context.disable_btn = false;
          Swal.fire("Error", "Document Type not found!", "error");
        }
      );
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Document Type" }]);

    const document_type_form = KTUtil.getById("kt_document_type_form");

    this.fv = formValidation(document_type_form, {});
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    togglePassword() {
      this.pass_show = !this.pass_show;
    },
    redirectToEditDT(ba_id) {
      this.$router.push({
        name: "documentType.edit",
        params: { id: ba_id }
      });
    },
    getCompanyUsers() {
      let context = this;
      this.$store.dispatch(GET_COMPANY_USERS).then(() => {
        if (this.companyUsers) {
          this.company_users = this.companyUsers;

          this.company_users.forEach(user => {
            this.company_user_list.push(user.first_name + " " + user.last_name);
          });
        } else {
          axios({
            method: "get",
            url: "get-company-users",
            baseURL: process.env.VUE_APP_ACBACK_URL
          }).then(function(result) {
            if (result.data.company_users) {
              result.data.company_users.forEach(user => {
                context.company_user_list.push(
                  user.first_name + " " + user.last_name
                );
              });
            }
          });
        }
      });
    },
    redirectToDocumentTypeList() {
      let context = this;
      context.$router.go(-1);
    },
  }
};
</script>
